.layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: var(--space-xs);
}

.meta {
    margin-top: var(--space-xs);
    display: flex;
    justify-content: space-between;
}

.overlay {
    position: absolute;
    z-index: 2;
    inset: 0;
}

.hero {
    display: block;
    position: relative;
    aspect-ratio: 16/9;
    overflow: hidden;
    clip-path: polygon( 0% 0%, calc( 100% - var(--space-sm)) 0, 100% var(--space-sm), 100% 100%, var(--space-sm) 100%, 0 calc(100% - var(--space-sm)) );
}
.hero img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.info {
    padding-left: var(--space-xs);
}




.titleLink {
    display: block;
    text-decoration: none;
    /* transition: all 350ms ease-in-out; */
}
/* @media(hover:hover) {
    .titleLink:hover {
        color: var(--secondary);
    }
} */