.positioner {
    position: relative;
}
.layout {
    position: absolute;
    z-index: 2;   
    top: calc(20vw * -1);
    width: 40vw;
    
    aspect-ratio: 1;

    /* border: 1px dashed lime; */
}

.layout.left {
    left: 0;
}
.layout.right {
    right: 0;
}

@media(min-width:600px){
    .layout {
        top: calc(14vw * -1);
        width: 28vw;
    }
}

@media(min-width:900px){
    .layout {
        top: calc(10vw * -1);
        width: 20vw;
    }
}