.heroImage {
    clip-path: polygon( 0% 0%, calc( 100% - var(--space-xl)) 0, 100% var(--space-xl), 100% 100%, var(--space-xl) 100%, 0 calc(100% - var(--space-xl)) );
    background-color: black;
    width: 100%;
}


@media(min-width:900px){
    .heroImage {
        width: 80vw;
    }
}