.circleContainer {
    position: relative;
    border-radius: 50%;
    aspect-ratio: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: -20.75%;
}

.quadrant {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    transform-origin: bottom right;
    overflow: hidden;
}

.pulse {
    position: absolute;
    z-index: 7;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 4rem solid rgb(255, 72, 0);
    background-image: radial-gradient(rgba(255, 174, 0, 0), rgba(255, 174, 0, 0), rgba(255, 51, 0, 0.8));
    animation: pulse 4s ease-out infinite;
}

.centerCircle {
    position: absolute;
    z-index: 5;
    width: 6vw;
    height: 6vw;
    border-radius: 6vw;
}

.pulseLarge {
    position: absolute;
    right: -50%;
    bottom: -50%;
    z-index: 7;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2rem solid rgba(0, 150, 142, 0.679);
    animation: pulseLarge 4s ease-out infinite;
}
.pulseSmall {
    position: absolute;
    z-index: 6;
    width: 20%;
    height: 20%;
    border-radius: 100%;
    border: 1vw solid rgb(255, 232, 59);
    opacity: .5;
    animation: pulseSmall 5s 1s ease-out infinite;
}



@keyframes pulse {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
@keyframes pulseLarge {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(2.2);
        opacity: 0;
    }
}
@keyframes pulseSmall {
    0% {
        transform: scale(.5);
        opacity: .25;
    }
    10% {
        opacity: 1;
    }
    100% {
        transform: scale(4);
        opacity: 0;
    }
}




@media(max-width:599px){

    .pulse {
        border: 5rem solid rgb(255, 72, 0);
    }
    
    .centerCircle {
        width: 8vw;
        height: 8vw;
        border-radius: 8vw;
    }
    
    .pulseLarge {
        border: 2rem solid rgba(0, 150, 142, 0.679);
    }
    .pulseSmall {
        border-radius: 100%;
        border: 2vw solid rgb(255, 232, 59);
    }

    .mobileHide {
        display: none;
    }
}





.layoutGrid {
    display: grid;
    width: 100%;
}
@media(min-width:900px){
    .layoutGrid {
        display: grid;
        width: 60%;
    }
}

/* deep layout */

@media(max-width:1600px) and (min-height:1000px) {

    .layoutGrid {
        display: grid;
        width: 80%;
    }
}

/* notebook viewports */

@media(max-width:1400px) and (max-height:800px) and (orientation: landscape) {
    .layoutGrid {
        display: grid;
        width: 50%;
    }
}





.layoutGridPageHeader {
    display: grid;
    width: 100%;
}

@media(min-width:900px){
    .layoutGridPageHeader {
        display: grid;
        width: 80%;
    }
}

/* notebook viewports */

@media(max-width:1400px) and (max-height:800px) and (orientation: landscape) {
    .layoutGridPageHeader {
        display: grid;
        width: 80%;
    }
}