.logo {
    display: inline-block;
    position: relative;
    width: 10rem;
    mix-blend-mode: multiply;
}
.logo.lg {
    width: 12rem;
}
.logo img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media(min-width:600px) {
    .logo {
        width: 12rem;
    }
    .logo.lg {
        width: 18rem;
    }
}