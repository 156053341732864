.layout {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-sm);
    padding: var(--space-md);

    color: white;
}

.themeHeading p {
    color: white;
    font-size: 9vw;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
}
.themeHeading p:first-of-type {
    margin-bottom: var(--space-xs);
}



@media(max-width:599px){
    .themeHeading {
        margin-top: 4rem;
    }
}
@media(max-width:899px){
    .themeHeading {
        grid-row-start: 1;
    }
}



@media(min-width:600px) {
    .layout {
        position: absolute;
        bottom: 0;
        right: 0;
        align-items: flex-end;
    }
    .themeHeading p {
        font-size: 7vw;
    }
}
@media(min-width:900px) {
    .layout {
        max-width: 80vw;
        grid-template-columns: 1fr 2fr;
        gap: var(--space-md);
    }
    .themeHeading p {
        font-size: 5vw;
    }
}



/* notebook viewports */

@media(max-width:1400px) and (max-height:800px) and (orientation: landscape) {
    .layout {
        max-width: 90vw;
        grid-template-columns: 2fr 2fr;
        gap: var(--space-sm);
    }
    .themeHeading p {
        font-size: 4.75vw;
    }
}