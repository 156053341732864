.layout {
    display: grid;
    grid-template-columns: 1fr;
}

.heading,
.content {
    padding: var(--space-md);
}

@media(min-width:800px) {
    .layout {
        grid-template-columns: 1fr 1fr;
        row-gap: var(--space-md);
    }

    .heading {
        padding: var(--space-xl) 0 0 var(--space-xl);
    }

    .content {
        grid-area: 2/1 / 3/3;
        padding: 0 var(--space-xl) var(--space-xl);
    }

    .graphic {
        grid-area: 1/2 / 2/3;
        background-color: white;
        height: var(--layout-offset);
        /* margin-top: calc( var(--layout-offset) * -1); */
    }
}


.icon {
    fill: purple;
}